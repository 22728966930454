<template>

  <the-hero> </the-hero>

</template>

<script>

import TheHero from  '../components/TheHero.vue';

export default {

     components: {

      TheHero,
   },



}

</script>
<style>
</style> 
